import ContentBlock from "../../components/UI/ContentBlock"
import ContentCard from "../../components/UI/ContentCard"
import {Button} from "antd"
import styled from "styled-components"
import ContentHeader from "../../components/UI/ContentHeader"
import {useEffect} from "react"

const MonitoringScreen = () => {

	useEffect(() => {
		document.title = 'АТС24 — Мониторинг'
	}, [])

	return (
		<>
			<ContentBlock top={60} bottom={0}>
				<ContentCard title="Мониторинг" large>
					<p>
						АТС24 — простой и мощный инструмент для планирования маршрутов. Можно вручную и автоматически с
						возможностью ручной корректировкой.
					</p>
					<p>
						<Button color="default" variant="solid" size="large">
							Попробовать бесплатно
						</Button>
					</p>
				</ContentCard>
			</ContentBlock>
			<ContentBlock top={40}>
				<Section>
					<img width="100%" src="./assets/img/png/home/main.png"/>
				</Section>
			</ContentBlock>
			<ContentBlock>
				<ContentCard title="Всей командой" small>
					<p>
						Разделяйте точки по отвественности и маршрутизируйте всей командой одновременно. Вы всегда
						будете видеть то, что делают ваши коллеги.
					</p>
				</ContentCard>
			</ContentBlock>
			<ContentBlock background="#E9FAFF">
				<ContentCard title="Автоматически или вручную" small>
					<p>
						Любая автоматизация — прекрасно. Но мы считаем, что ручной режим не менее важен, поэтому у нас
						есть оба инструмента.
					</p>
				</ContentCard>
			</ContentBlock>
			<ContentBlock top={0} bottom={0} background="#E9FAFF">
				<ContentCard
					reverse title="Автоматически" mini
					media="https://cdn.sanity.io/images/599r6htc/regionalized/4baf0143722b830307500d7d1b1c32c79a8f87d5-1560x1094.png?w=780&q=75&fit=max&auto=format&dpr=2"
				>
					<p>
						Мы используем только нужные и понятные настройки автоматического планирования, а более тонкие
						подгонки алгоритм выполнит самостоятельно.
					</p>
					<p>
						Вам необходимо только выбрать склад, указать приоритет, ограничения на количество адресов или
						времени работы, дальше автомат сделает всё за вас.
					</p>
				</ContentCard>

				<ContentCard
					title="Ручной режим" mini
					media="https://cdn.sanity.io/images/599r6htc/regionalized/b80d3a64d6988323c011088c92f65d7161e733cd-1108x1108.png?w=780&q=75&fit=max&auto=format&dpr=2"
				>
					<p>
						Более простого, удобного и информативного конструктора маршрутов вы ещё не встречали.
					</p>
					<p>
						Всё, как в операционных системах — копируйте, вырезайте и вставляйте точки прямо на карте.
					</p>
				</ContentCard>
			</ContentBlock>
			<ContentBlock>
				<TilesWrapper>
					<TileWrapper>
						<img width={540}
							 src="https://cdn.sanity.io/images/599r6htc/regionalized/6538929bdeab8c11b7e97274a48e55fb68c701c3-1560x1248.png?w=540&h=432&q=75&fit=max&auto=format&dpr=2"/>
						<ContentHeader mini>Работа в браузере</ContentHeader>
						<p>
							Никаких установок, серверов, выездов специалистов и других трудностей, которые встречаются в
							классичеком ПО.
						</p>
					</TileWrapper>
					<TileWrapper>
						<img width={540}
							 src="https://cdn.sanity.io/images/599r6htc/regionalized/6538929bdeab8c11b7e97274a48e55fb68c701c3-1560x1248.png?w=540&h=432&q=75&fit=max&auto=format&dpr=2"/>
						<ContentHeader mini>Очень быстрый</ContentHeader>
						<p>
							Вы когда-нибудь работали с 10 000 точками на карте? У нас вы можете ещё и управлять ими без
							торможений.
						</p>
					</TileWrapper>
					<TileWrapper>
						<img width={540}
							 src="https://cdn.sanity.io/images/599r6htc/regionalized/6538929bdeab8c11b7e97274a48e55fb68c701c3-1560x1248.png?w=540&h=432&q=75&fit=max&auto=format&dpr=2"/>
						<ContentHeader mini>Маршруты по дорогам</ContentHeader>
						<p>
							Мы считаем все маршруты прямо по дорогам, и отдаём точные данные о расстояниях, времени и
							схемы движения.
						</p>
					</TileWrapper>
					<TileWrapper>
						<img width={540}
							 src="https://cdn.sanity.io/images/599r6htc/regionalized/6538929bdeab8c11b7e97274a48e55fb68c701c3-1560x1248.png?w=540&h=432&q=75&fit=max&auto=format&dpr=2"/>
						<ContentHeader mini>Метрики</ContentHeader>
						<p>
							По каждому маршруту мы отдаем плановое время выезда, прибытия на точки и возвращение на
							базу, если это необходимо.
						</p>
					</TileWrapper>
					<TileWrapper>
						<img width={540}
							 src="https://cdn.sanity.io/images/599r6htc/regionalized/6538929bdeab8c11b7e97274a48e55fb68c701c3-1560x1248.png?w=540&h=432&q=75&fit=max&auto=format&dpr=2"/>
						<ContentHeader mini>У водителя тоже</ContentHeader>
						<p>
							В приложении водитель в любой момент может оптимизировать маршрут для оставшихся точек в
							случае нештатной ситуации.
						</p>
					</TileWrapper>
					<TileWrapper>
						<img width={540}
							 src="https://cdn.sanity.io/images/599r6htc/regionalized/6538929bdeab8c11b7e97274a48e55fb68c701c3-1560x1248.png?w=540&h=432&q=75&fit=max&auto=format&dpr=2"/>
						<ContentHeader mini>Геокодирование</ContentHeader>
						<p>
							У вас не хранятся координаты клиентов? Не проблема, мы геокодируем адреса и даём возможность
							указать место разгрузки.
						</p>
					</TileWrapper>
				</TilesWrapper>
			</ContentBlock>

			<ContentBlock background="#F3FFE3">
				<ContentCard
					reverse title="Быстрый поиск" mini
					media="https://cdn.sanity.io/images/599r6htc/regionalized/4baf0143722b830307500d7d1b1c32c79a8f87d5-1560x1094.png?w=780&q=75&fit=max&auto=format&dpr=2"
				>
					<p>
						Наш поиск мгновенно находит информацию по более чем 30 полям внутри водителей,
						автомобилей, маршрутов, адресов и данных клиентов..
					</p>
				</ContentCard>

				<ContentCard
					title="Метки" mini
					media="https://cdn.sanity.io/images/599r6htc/regionalized/b80d3a64d6988323c011088c92f65d7161e733cd-1108x1108.png?w=780&q=75&fit=max&auto=format&dpr=2"
				>
					<p>
						Более простого, удобного и информативного конструктора маршрутов вы ещё не встречали.
					</p>
					<p>
						Всё, как в операционных системах — копируйте, вырезайте и вставляйте точки прямо на карте.
					</p>
				</ContentCard>

				<ContentCard
					reverse
					title="Персональные фильтры" mini
					media="https://cdn.sanity.io/images/599r6htc/regionalized/4baf0143722b830307500d7d1b1c32c79a8f87d5-1560x1094.png?w=780&q=75&fit=max&auto=format&dpr=2"
				>
					<p>
						Более простого, удобного и информативного конструктора маршрутов вы ещё не встречали.
					</p>
					<p>
						Всё, как в операционных системах — копируйте, вырезайте и вставляйте точки прямо на карте.
					</p>
				</ContentCard>
			</ContentBlock>

			<ContentBlock>
				<ContentCard title="Перенос точек" small
							 media="https://cdn.sanity.io/images/599r6htc/regionalized/4baf0143722b830307500d7d1b1c32c79a8f87d5-1560x1094.png?w=780&q=75&fit=max&auto=format&dpr=2"
				>
					<p>
						В пути сломалось ТС или не вышел водитель? Не проблема! Вы можете быстро сменить транспорт,
						водителя или перераспределить оставшиеся точки по другим маршрутам.
					</p>
				</ContentCard>
			</ContentBlock>

			<ContentBlock>
				<ContentCard reverse title="Печать документов" small
							 media="https://cdn.sanity.io/images/599r6htc/regionalized/4baf0143722b830307500d7d1b1c32c79a8f87d5-1560x1094.png?w=780&q=75&fit=max&auto=format&dpr=2"
				>
					<p>
						Нажмите правой кнопкой мыши на маршрут, выберите нужный документ (путевой лист или задание) и
						отправьте его на печать. В
						нем уже будет автоматически заполнена форма с данными водителя, ТС и списком адресов.
					</p>
				</ContentCard>
			</ContentBlock>
			{/*<ContentBlock>
				<ContentCard title="Простая интеграция" mini>
					<p>
						Вы можете интегрировать АТС24 с любой CRM/ERP/WMS системой по протоколам API или простейшими
						файлами через SFTP.
					</p>
					<p>
						Мы окажем полную поддержку для скорейшей реализации.
					</p>
					<p>
						<img
							src="https://3ab0ed31-049c5350-6544-4365-ae7e-6e7e2957eeea.s3.timeweb.cloud/iblock/d66/d66e7fbb44ee29b117b19a81bec09f94/d90a564825cbd92c6e61da914dadc090.png"
							height={100}/>
						<img
							src="https://kkm24.ru/image/catalog/demo/mojsklad.jpg"
							height={100}/>
						<img
							src="https://www.thesmbguide.com/images/bitrix24-1024x512-20200303.png"
							height={100}/>
					</p>
				</ContentCard>
			</ContentBlock>*/}
		</>
	)
}

export default MonitoringScreen

const Section = styled.div`
    -webkit-box-shadow: 0px 0px 10px 2px rgba(34, 60, 80, 0.2);
    -moz-box-shadow: 0px 0px 10px 2px rgba(34, 60, 80, 0.2);
    box-shadow: 0px 0px 10px 2px rgba(34, 60, 80, 0.2);
    border-radius: 10px;
    overflow: hidden;
`
const TilesWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 80px;
    width: 1160px;
`
const TileWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 540px;

    img {
        margin-bottom: 32px;
    }
`
