import ContentBlock from "../../components/UI/ContentBlock"
import ContentCard from "../../components/UI/ContentCard"
import {Button} from "antd"
import styled from "styled-components"
import 'swiper/css'
import MultiServiceSwiper from "./components/MultiServiceSwiper"

const HomeScreen = () => {

	return (
		<>
			<ContentBlock top={60} bottom={0}>
				<ContentCard title="Цифровая логистика" large>
					<p>Самые современные инструменты для логистики на единой платформе.</p>
					<p>
						<Button color="default" variant="solid" size="large">
							Попробовать бесплатно
						</Button>
					</p>
				</ContentCard>
			</ContentBlock>
			<ContentBlock top={40}>
				<Section>
					<img width="100%" src="./assets/img/png/home/main.png"/>
				</Section>
				<ButtonsWrapper>
					<SectionButton className="active">Маршрутизация</SectionButton>
					<SectionButton>Мониторинг</SectionButton>
					<SectionButton>Мобильное приложение</SectionButton>
				</ButtonsWrapper>
				<SectionDescription>
					Маршрутизируй вместе со своей командой прямо в браузере.
				</SectionDescription>
			</ContentBlock>
			<ContentBlock top={0}>
				<ReviewWrapper>
					<Review>
						<Quote>❝</Quote>
						Теперь вся логистика управляется на единой платформе.
					</Review>
					<Customer>
						<CustomerTitle>
							RGW
						</CustomerTitle>
						<CustomerName>
							<Avatar>
								<img
									src="https://sun9-39.userapi.com/s/v1/ig2/cMCu2CFL8N-6Wis6g5nCLqwon7eD5_Cxu1g4h-pVfawKlLVJX1Z3NYCeG03w1lWQ3uIiA6FPZ-L2WqnUdH2q3VkH.jpg?quality=95&blur=50,20&as=32x43,48x64,72x96,108x144,160x213,240x320,360x480,480x640,540x720,640x853,720x960,1080x1440,1200x1600&from=bu&u=3bRNs181kBIXqLlRVI9kqeYsJQXJCrxxWsczNLGt41g&cs=453x604"/>
							</Avatar>
							<Name>
								<div>Буззубов Евгений</div>
								<div>Руководитель логистики</div>
							</Name>
						</CustomerName>
					</Customer>
				</ReviewWrapper>
			</ContentBlock>
			<ContentBlock background="#F3FFE3">
				<ContentCard title="Простота">
					<p>
						Наши интерфейсы интуитивно понятны, и любой сотрудник освоит их всего за час. Все экраны были
						разработаны с участием логистов, водителей и руководителей отделов.
					</p>
					<p>
						<Button color="default" variant="solid" size="large">
							Подробней о маршрутизации
						</Button>
					</p>
				</ContentCard>
			</ContentBlock>
			<ContentBlock background="#E4FF97">
				<ContentCard title="Скорость">
					<p>
						Потрясающая скорость работы даже на офисных ПК. Терминал и маршрутизация эффективно обрабатывают
						большое количество маршрутов, а карта без задержек может отображать более 10 000 точек
						одновременно.
					</p>
					<p>
						<Button color="default" variant="solid" size="large">
							Подробней о мониторинге
						</Button>
					</p>
				</ContentCard>
			</ContentBlock>
			<ContentBlock background="#FFF2DF">
				<ContentCard title="Модульная платформа">
					<p>
						Мы внимательно следим за тенденциями и рынка логистики и регулярно запускаем
						новые, современные сервисы, доступные сразу всем клиентам.
					</p>
				</ContentCard>
				<MultiServiceSwiper/>
			</ContentBlock>
			<ContentBlock style={{position: 'relative'}}>
				<ContentCard title="Приложение руководителя">
					<p>
						Мы внимательно следим за тенденциями и потребностями рынка логистики и регулярно запускаем
						новые, современные сервисы, доступные всем клиентам.
					</p>
					<p>
						<Button color="default" variant="solid" size="large">
							Подробней о приложении
						</Button>
					</p>
				</ContentCard>
				<img width={500} style={{position: 'absolute', right: 0, bottom: 0}}
					 src="https://cdn.sanity.io/images/599r6htc/regionalized/5ee6b7f438fa1f6a463a8c8f27cfa91f24ddb13f-1560x1248.png?w=908&q=75&fit=max&auto=format&dpr=2"/>
			</ContentBlock>
		</>
	)
}

export default HomeScreen

const Section = styled.div`
    -webkit-box-shadow: 0px 0px 10px 2px rgba(34, 60, 80, 0.2);
    -moz-box-shadow: 0px 0px 10px 2px rgba(34, 60, 80, 0.2);
    box-shadow: 0px 0px 10px 2px rgba(34, 60, 80, 0.2);
    border-radius: 10px;
    overflow: hidden;
`
const ButtonsWrapper = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 30px;
    gap: 2px;

    .active {
        background-color: #EEEEEE;
    }
`
const SectionButton = styled.div`
    padding: 0.5rem 1.125rem 0.625rem;
    border-radius: 3.125rem;
    cursor: pointer;
    font-size: 18px;

    :hover {
        background-color: #EEEEEE;
        transition: background-color .3s;
    }
`
const SectionDescription = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 30px;
    font-size: 24px;
    font-weight: 300;
`
const ReviewWrapper = styled.div`
    display: flex;
    gap: 120px;
`
const Review = styled.div`
    display: flex;
    flex-direction: column;
    font-size: 56px;
`
const Quote = styled.div`
    display: flex;
    font-size: 200px;
    line-height: 150px;
    color: #B98E00;
`
const Customer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    min-width: 300px;
`
const CustomerTitle = styled.div`
    font-size: 32px;
    font-weight: 700;
    padding-bottom: 20px;
`
const CustomerName = styled.div`
    display: flex;
    gap: 20px;
    align-items: center;
`
const Avatar = styled.div`
    display: flex;
    gap: 10px;
    border: dashed 3px #B98E00;
    width: 60px;
    height: 60px;
    border-radius: 60px;
    overflow: hidden;

    img {
        width: 60px;
        height: 60px;
        -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
        filter: grayscale(100%);
    }
`
const Name = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
    font-size: 18px;
    font-weight: 330;
    animation: 90s linear infinite
`
