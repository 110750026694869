import styled from "styled-components"
import React from "react"
import Icon from "../../components/Icon/Icon"
import {faLeaf} from "@fortawesome/pro-solid-svg-icons"
import {HashLink} from 'react-router-hash-link'

const Footer = ({dark = false}) => {

	return (
		<Wrapper dark={dark}>
			<Company>
				АТС24
			</Company>
			<Navigation>
				<NavigationColumn>
					<NavigationTitle>Маршрутизация</NavigationTitle>
					<HashLink to="/routing#map">Карта</HashLink>
					<HashLink to="/routing#metrics">Просчёт маршрутов</HashLink>
					<HashLink to="/routing#geocode">Геокодер</HashLink>
					<HashLink to="/routing#schedule">График отгрузки ТС</HashLink>
					<HashLink to="/routing#performance">Быстродействие</HashLink>
					<HashLink to="/routing#group-use">Совместное использование</HashLink>
				</NavigationColumn>
				<NavigationColumn>
					<NavigationTitle>Мониторинг</NavigationTitle>
					<HashLink to="/monitoring#terminal">Терминал</HashLink>
					<HashLink to="/monitoring#logs">Журнал событий</HashLink>
					<HashLink to="/monitoring#divisions">Поддержка филиалов</HashLink>
					<HashLink to="/monitoring#archive">Архив маршрутных листов</HashLink>
					<HashLink to="/monitoring#gps-sensor">GPS мониторинг</HashLink>
					<HashLink to="/monitoring#fuel-sensor">Контроль расхода топлива</HashLink>
					<HashLink to="/monitoring#temp-sensor">Температурный режим</HashLink>
					<HashLink to="/monitoring#door-sensor">Датчик открытия ворот</HashLink>
					<HashLink to="/monitoring#can-bus">CAN шина</HashLink>
					<HashLink to="/monitoring#eco-drive"><Icon name={faLeaf} size={14} color="#7cb305" right={2}/>ECO
						Drive</HashLink>
				</NavigationColumn>
				<NavigationColumn>
					<NavigationTitle>Приложение</NavigationTitle>
					<HashLink to="/mobile-app#route">Маршрутный лист</HashLink>
					<HashLink to="/mobile-app#points-info">Информация о точках</HashLink>
					<HashLink to="/mobile-app#invoices">Товарная накладная</HashLink>
					<HashLink to="/mobile-app#check-lists">Чек-листы</HashLink>
					<HashLink to="/mobile-app#photo-fixation">Фото-фиксация</HashLink>
					<HashLink to="/mobile-app#barcode-scanner">Сканер штрих-кодов</HashLink>
					<HashLink to="/mobile-app#government-fines">Штрафы ГИБДД</HashLink>
					<HashLink to="/mobile-app#job-calendar">Календарь поездок</HashLink>
				</NavigationColumn>
				<NavigationColumn>
					<NavigationTitle>Сервисы</NavigationTitle>
					<HashLink to="/routing#map">Карта</HashLink>
					<HashLink to="/routing#metrics">Просчёт маршрутов</HashLink>
					<HashLink to="/routing#geocode">Определение координат</HashLink>
					<HashLink to="/routing#schedule">График отгрузки ТС</HashLink>
					<HashLink to="/routing#performance">Быстродействие</HashLink>
					<HashLink to="/routing#group-use">Групповое использование</HashLink>

					<NavigationTitle style={{marginTop: '3rem'}}>Дополнительно</NavigationTitle>
					<HashLink to="/routing#map">Тарифы</HashLink>
					<HashLink to="/routing#map">Контакты</HashLink>
					<a href="/docs/privacy">Политика конфиденциальности</a>
					<a href="/docs/privacy">API</a>
				</NavigationColumn>
			</Navigation>
		</Wrapper>
	)
}

export default Footer

const Wrapper = styled.div`
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    width: 100%;
    padding: 120px calc((100vw - 1320px) / 2 + 25px);
    align-self: center;
    border: solid 1px #EEEEEE;
    background-color: ${props => props.dark ? '#000000' : '#FFFFFF'};
    color: ${props => props.dark ? '#FFFFFF' : '#000000'};

    a {
        text-decoration: none;
        color: ${props => props.dark ? '#FFFFFF' : '#000000'};
    }

    a:hover {
        text-decoration: underline;
        color: ${props => props.dark ? '#FFFFFF' : '#000000'};
    }
`
const Company = styled.div`
    display: flex;
    width: 270px;
    font-size: 43px;
    font-weight: 500;
`
const Navigation = styled.div`
    display: flex;
    gap: 50px;
`
const NavigationColumn = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;

`
const NavigationTitle = styled.div`
    text-transform: uppercase;
    color: #697485;
`
