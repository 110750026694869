import styled from "styled-components"
import {Swiper, SwiperSlide} from "swiper/react"
import {Pagination} from "swiper/modules"
import Icon from "../../../components/Icon/Icon"
import {faArrowLeft, faArrowRight} from "@fortawesome/pro-light-svg-icons"
import {useRef, useState} from "react"

const MultiServiceSwiper = () => {
	const swiperRef = useRef()

	const [reached, setReached] = useState('left')

	return (
		<Wrapper>
			<TopPanel>
				<LeftSide>
					Некоторые сервисы
				</LeftSide>
				<RightSide>
					<ControlButton onClick={() => swiperRef.current.swiper.slidePrev()}
								   disabled={reached === 'left'}>
						<Icon name={faArrowLeft} size={26}/>
					</ControlButton>
					<ControlButton onClick={() => swiperRef.current.swiper.slideNext()}
								   disabled={reached === 'right'}>
						<Icon name={faArrowRight} size={26}/>
					</ControlButton>
				</RightSide>
			</TopPanel>

			<ContentWrapper>
				<Swiper
					ref={swiperRef}
					slidesPerView={'auto'}
					spaceBetween={30}
					pagination={{clickable: true}}
					centeredSlidesBounds={true}
					modules={[Pagination]}
					style={{paddingLeft: 'calc((100vw - 1320px)/2 + 17px)', paddingRight: 25}}
					onSlideChange={e => setReached(e.isBeginning ? 'left' : e.isEnd ? 'right' : null)}
				>
					<SwiperSlide
						style={{width: 600, height: 600}}>
						<img width={600}
							 src="https://cdn.sanity.io/images/599r6htc/regionalized/ed6e1575c8d6883805697d4c1520fb30973d6dc5-1200x800.png?rect=1,0,1199,800&w=700&h=467&q=75&fit=max&auto=format"/>
						<SlideDescription>
							<b>Система мотивации водителей.</b> Streamline the product development process with a design
							system that’s aligned across design and code.
							<p><a>Узнать больше о системе мотивации</a></p>
						</SlideDescription>
					</SwiperSlide>
					<SwiperSlide
						style={{width: 600, height: 600}}>
						<img width={600}
							 src="https://cdn.sanity.io/images/599r6htc/regionalized/6763a77d77f4a293f47012dbe547af93c5030cd7-1320x880.png?rect=1,0,1319,880&w=700&h=467&q=75&fit=max&auto=format&dpr=2"/>
						<SlideDescription>
							<b>Telegram боты.</b> Streamline the product development process with a
							design
							system that’s aligned across design and code.
							<p><a>Узнать больше о системе мотивации</a></p>
						</SlideDescription>
					</SwiperSlide>
					<SwiperSlide
						style={{width: 600, height: 600}}>
						<img width={600}
							 src="https://cdn.sanity.io/images/599r6htc/regionalized/771714e605eb78af505cc99d18136308a48e43a6-1320x880.png?rect=1,0,1319,880&w=700&h=467&q=75&fit=max&auto=format&dpr=2"/>
						<SlideDescription>
							<b>Акт приёма-передачи ТС.</b> Streamline the product development process with a design
							system that’s aligned across design and code.
							<p><a>Узнать больше о системе мотивации</a></p>
						</SlideDescription>
					</SwiperSlide>
					<SwiperSlide
						style={{width: 600, height: 600}}>
						<img width={600}
							 src="https://cdn.sanity.io/images/599r6htc/regionalized/59b7504fab0d1f497159295d796bbfa289771461-1200x800.png?rect=1,0,1199,800&w=700&h=467&q=75&fit=max&auto=format&dpr=2"/>
						<SlideDescription>
							<b>Учёт возврата документов и тары.</b> Streamline the product development process with a
							design system that’s aligned across design and code.
							<p><a>Узнать больше о системе мотивации</a></p>
						</SlideDescription>
					</SwiperSlide>
				</Swiper>
			</ContentWrapper>
		</Wrapper>
	)
}

export default MultiServiceSwiper

const Wrapper = styled.div`
    height: 700px;
`
const TopPanel = styled.div`
    display: flex;
    margin: 24px 0;
    align-items: flex-end;
`
const LeftSide = styled.div`
    display: flex;
    flex: 1;
    font-size: 20px;
    text-transform: uppercase;
`
const RightSide = styled.div`
    display: flex;
    justify-content: flex-end;
    gap: 10px;
`
const ControlButton = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    border-radius: 48px;
    background-color: ${props => props.disabled ? 'default' : 'rgba(0, 0, 0, 5%)'};
    cursor: ${props => props.disabled ? 'default' : 'pointer'};
    border: solid 1px rgba(0, 0, 0, 5%);

    :hover {
        background-color: ${props => props.disabled ? 'default' : '#000000'};
        color: ${props => props.disabled ? 'default' : '#FFFFFF'};
    }
`
const ContentWrapper = styled.div`
    position: absolute;
    left: 0;
    right: 0;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
`
const SlideDescription = styled.div`
    padding-top: 20px;
    font-size: 24px;
    font-weight: 300;

    a {
        font-size: 18px;
    }
`
