import styled from "styled-components"

const ContentHeader = ({children, ...props}) => {
	return (
		<Wrapper {...props}>
			{children}
		</Wrapper>
	)
}

export default ContentHeader

const Wrapper = styled.div`
    font-size: ${props => props.large ? 86 : props.small ? 44 : props.mini ? 32 : 56}px;
    font-weight: ${props => props.mini ? 300 : 400};
    line-height: 0.85;
`
