import styled from "styled-components"
import ContentBlock from "../../components/UI/ContentBlock"
import {Button, Col, Form, Input, message, Radio, Row, Space} from "antd"
import {useEffect, useState} from "react"

const ContactScreen = () => {

	const [contact, setContact] = useState(null)
	const [area, setArea] = useState(null)
	const [amount, setAmount] = useState(null)
	const [description, setDescription] = useState('')

	useEffect(() => {
		document.title = 'АТС24 — Контакты'
	}, [])

	const sendForm = async () => {
		await fetch('https://api.ats24.ru/v2/feedback', {
			method: "POST",
			body: JSON.stringify({contact, area, amount, description}), // данные могут быть 'строкой' или {объектом}!
			headers: {
				"Content-Type": "application/json"
			}
		})

		setContact('')
		setArea(null)
		setAmount(null)
		setDescription(null)

		message.open({
			type: 'success',
			content: 'Форма успешно отправлена. В скором времени мы свяжемся с вами.',
			duration: 4
		})
	}

	return (
		<ContentBlock top={120}>
			<ContentWrapper>
				<ContactWrapper>
					<ContactUs>Связаться с нами</ContactUs>
					<Description>
						<p>С вами свяжется специалист по продукту и IT, который проконсультирует по любому вопросу, в
							том числе интеграции.</p>
						<p>Звоните нам по телефону +7 (495) 212-91-55</p>
						<p>Мы работаем ежедневно с 09:00 до 20:00 по московскому времени.</p>
					</Description>
				</ContactWrapper>
				<FormWrapper>
					<Form>
						<MailBlock>
							<Input value={contact} onChange={e => setContact(e.target.value)}
								   placeholder="Ваш email, телефон или аккаунт в Telegram"/>
						</MailBlock>
						<MailBlock>
							<Row gutter={50}>
								<Col span={12}>
									<Title>Сфера деятельности</Title>
									<Radio.Group onChange={e => setArea(e.target.value)} value={area}>
										<Space direction="vertical" size={15}>
											<Radio value="food">Продукты питания</Radio>
											<Radio value="groupCargo">Сборные грузы</Radio>
											<Radio value="domestiServices">Бытовые услуги</Radio>
											<Radio value="freeze">Заморозка</Radio>
											<Radio value="lorry">Магистральные перевозки</Radio>
											<Radio value="another">другая</Radio>
										</Space>
									</Radio.Group>
								</Col>
								<Col span={12}>
									<Title>Количество адресов в сутки</Title>
									<Radio.Group onChange={e => setAmount(e.target.value)} value={amount}>
										<Space direction="vertical" size={15}>
											<Radio value="10-50">10
												<d>—</d>
												50</Radio>
											<Radio value="50-500">50
												<d>—</d>
												500</Radio>
											<Radio value="500-2000">500
												<d>—</d>
												2 000</Radio>
											<Radio value="2000-5000">2 000
												<d>—</d>
												5 000</Radio>
											<Radio value="5000-10000">5 000
												<d>—</d>
												10 000</Radio>
											<Radio value=">10000">более 10 000</Radio>
										</Space>
									</Radio.Group>
								</Col>
							</Row>

						</MailBlock>
						<MailBlock>
							<Input.TextArea onChange={e => setDescription(e.target.value)} rows={6} value={description}
											placeholder="Задачи, которые вы хотите решить"/>
						</MailBlock>
						<Button type="primary" disabled={!contact?.length} onClick={() => sendForm()}
								size="large" color="default" variant="solid"
								htmlType="submit">Отправить</Button>
					</Form>
				</FormWrapper>
			</ContentWrapper>
		</ContentBlock>
	)
}

export default ContactScreen

const ContentWrapper = styled.div`
    display: flex;
    gap: 6rem;
`
const ContactWrapper = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
`
const ContactUs = styled.div`
    font-size: 48px;
    font-weight: 700;
`
const Description = styled.div`
    font-size: 24px;
    font-weight: 300;
`
const FormWrapper = styled.div`
    display: flex;
    border-radius: 14px;
    background-color: #F7F7F7;
    padding: 40px;
`

const MailBlock = styled.div`
    padding-bottom: 32px;

    d {
        padding-inline: 3px;
    }
`
const Title = styled.div`
    font-size: 14px;
    padding-bottom: 10px;
`
/*
const ContactWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`
const InfoWrapper = styled.div`
  flex: 1;
  min-width: 400px;

  p {
    font-size: 20px;
    font-weight: 300;
    color: ${props => props.theme.colorTextDescription};
    line-height: 32px;
  }
`
const MailWrapper = styled.div`
  flex: 1;
  min-width: 500px;
  max-width: 700px;
  margin-left: 50px;
  padding: 50px;
  border-radius: 8px;
  background: ${props => props.theme.colorBgCard};
`
*/
