import styled from "styled-components"
import {Link, useNavigate} from "react-router-dom"
import {Button} from "antd"
import Icon from "../../components/Icon/Icon"
import {faChevronDown} from "@fortawesome/pro-regular-svg-icons/faChevronDown"
import logo from "../../assets/svg/logo.svg"
import {UserOutlined} from "@ant-design/icons"

const menu = [
	{key: "routing", title: "Маршрутизация"},
	{key: "monitoring", title: "Мониторинг"},
	{key: "mobile-app", title: "Мобильное приложение"},
	{key: "services", title: "Сервисы", children: true},
	{key: "price", title: "Тарифы"},
	{key: "contact", title: "Контакты"}
]

const Header = () => {

	const navigate = useNavigate()

	return (
		<Fixed>
			<Wrapper>
				<Logo onClick={() => navigate('/')}>
					<img style={{height: 40}} src={logo}/>
				</Logo>
				<Navigation>
					{
						menu.map(item => (
							<MenuItem
								key={"menu-item-" + item.key}
								onClick={() => navigate('/' + item.key)}
							>
								{item.title} {item.children ? <Icon name={faChevronDown}/> : null}
							</MenuItem>
						))
					}
				</Navigation>
				<Login>
					<Link to="https://app.ats24.ru" target="_blank">
						<Button color="default" variant="solid" icon={<UserOutlined/>}>
							Аккаунт
						</Button>
					</Link>
				</Login>
			</Wrapper>
		</Fixed>
	)
}

export default Header

const Fixed = styled.div`
    display: flex;
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 10;
    justify-content: center;
    border-bottom: solid 1px #EEEEEE;
    background-color: #FFFFFF;
`
const Wrapper = styled.div`
    display: flex;
    flex: 1;
    max-width: 1320px;
    padding: 15px 25px;
    align-items: center;
    gap: 8px;
`
const Logo = styled.div`
    display: flex;
    cursor: pointer;
    width: 50px;
    /*-webkit-filter: grayscale(100%);
    filter: grayscale(100%);*/
`
const Navigation = styled.div`
    display: flex;
    flex: 1;
    align-items: center;
    gap: 15px;
`
const MenuItem = styled.a`
    font-size: 18px;
    font-weight: 300;
    cursor: pointer;
    padding: 8px;
    text-decoration: none;

    :hover {
        text-decoration: underline;
    }
`
const Login = styled.div`
    display: flex;
`
