import ContentBlock from "../../components/UI/ContentBlock"
import ContentCard from "../../components/UI/ContentCard"
import styled from "styled-components"
import React, {useEffect, useMemo, useState} from "react"
import {Badge, Button, Card, Checkbox, Divider, InputNumber} from "antd"
import Slider from "rc-slider"
import AsyncStorage from "@react-native-async-storage/async-storage"
import {prettyDigit} from "../../library/methods/prettyDigit"
import {round} from "lodash"

const inputNumberProps = {
	style: {width: 110, fontSize: 16, fontWeight: '500', padding: 0, margin: 0},
	formatter: (value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
}

const options = [
	{type: 'routing', title: 'Маршрутизация'},
	{type: 'checkLists', title: 'Чек-листы'},
	{type: 'sensors', title: 'Датчики GPS'},
	{type: 'fines', title: 'Штрафы ГИБДД'},
	{type: 'motivation', title: 'Система мотивации'},
	{type: 'service', title: 'Гараж'},
]

const PriceScreen = () => {

	const [settings, setSettings] = useState(null)

	useEffect(() => {
		document.title = 'АТС24 — Тарифы'
	}, [])

	useEffect(() => {
		load()
	}, [])

	const result = useMemo(() => {
		const data = {
			monitoring: {title: "Мониторинг", price: 0},
			mobileApp: {title: "Мобильное приложение", price: 0}
		}

		if (!settings) return data

		for (let i = 1; i < settings.count.points + 1; i++) {
			if (i >= 1 && i <= 7500) data.monitoring.price += 2.66
			if (i >= 7501 && i <= 13500) data.monitoring.price += 2
			if (i >= 13501 && i <= 27000) data.monitoring.price += 1.66
			if (i >= 27001 && i <= 45000) data.monitoring.price += 1.4
			if (i >= 45001 && i <= 90000) data.monitoring.price += 1.2
			if (i >= 90001 && i <= 135000) data.monitoring.price += 1
			if (i >= 135001 && i <= 180000) data.monitoring.price += 0.73
			if (i >= 180001 && i <= 270000) data.monitoring.price += 0.53
		}

		if (settings.options.routing) {
			data.routing = {title: "Маршрутизация", price: 0}
			for (let i = 1; i < settings.count.points + 1; i++) {
				if (i >= 1 && i <= 10000) data.routing.price = 10000 * 1.25
				if (i >= 10001 && i <= 18000) data.routing.price += 0.99
				if (i >= 18001 && i <= 36000) data.routing.price += 0.83
				if (i >= 36001 && i <= 60000) data.routing.price += 0.69
				if (i >= 60001 && i <= 120000) data.routing.price += 0.59
				if (i >= 120001 && i <= 180000) data.routing.price += +0.49
				if (i >= 180001 && i <= 240000) data.routing.price += +0.41
				if (i >= 240001 && i <= 360000) data.routing.price += +0.34
			}
		}

		if (settings.options.checkLists) {
			data.routing = {title: "Чек-листы", price: settings.count.points * 0.33}
		}

		if (settings.options.sensors && settings.count.transport) {
			data.sensors = {title: "GPS датчики", price: 0}
			for (let i = 1; i < settings.count.transport + 1; i++) {
				if (i >= 1 && i <= 5) data.sensors.price += 600
				if (i >= 6 && i <= 15) data.sensors.price += 450
				if (i >= 16 && i <= 30) data.sensors.price += 360
				if (i >= 31) data.sensors.price += 290
			}
		}

		if (settings.options.fines && settings.count.transport) {
			data.fines = {title: "Штрафы ГИБДД", price: settings.count.transport * 200}
			if (settings.count.transport > 15 && settings.count.transport <= 30) data.fines.price = 4000
			if (settings.count.transport > 30 && settings.count.transport <= 100) data.fines.price = 6000
			if (settings.count.transport > 100 && settings.count.transport <= 200) data.fines.price = 9000
			if (settings.count.transport > 200 && settings.count.transport <= 300) data.fines.price = 12000
			if (settings.count.transport > 300 && settings.count.transport <= 400) data.fines.price = 14000
			if (settings.count.transport > 400 && settings.count.transport <= 600) data.fines.price = 18000
		}

		return data
	}, [settings])

	const total = useMemo(() => {
		let data = 0
		Object.keys(result).map(key => {
			if (result[key].price > 0) data += result[key].price
		})

		return round(data)
	}, [result])

	const load = async () => {
		let params = await AsyncStorage.getItem('calculator')
		if (params) {
			params = JSON.parse(params)
		} else {
			params = {}
		}

		params = {
			...{
				count: {
					points: 10000,
					transport: 0
				},
				options: {
					routing: false,
					sensors: false,
					checkLists: false,
					fines: false,
					motivation: false
				}
			}, ...params
		}

		setSettings(params)
	}

	const onChangeCount = (type, value) => {
		let params = {...settings}
		params.count[type] = value
		setSettings(params)
		AsyncStorage.setItem('calculator', JSON.stringify(params))
	}

	const onChangeOptions = (type) => {
		let params = {...settings}
		params.options[type] = !params.options[type]
		setSettings(params)
		AsyncStorage.setItem('calculator', JSON.stringify(params))
	}

	const onBlurCount = () => {
		let params = {...settings}
		if (params.count.points < 10000) params.count.points = 10000
		if (params.count.transport === null) params.count.transport = 0
		setSettings(params)
	}

	if (!settings) return null

	return (
		<>
			<ContentBlock top={60} bottom={0}>
				<ContentCard title="Тарифный калькулятор">
					<p>
						Оплачивайте только за объем адресов и транспортных средств, который необходим вашей компании.
					</p>
				</ContentCard>
			</ContentBlock>

			<ContentBlock top={40}>
				<CalculatorWrapper>
					<ParamsWrapper>
						<Param>
							<ParamHeader>
								<ParamTitle>Точек (адресов) в месяц</ParamTitle>
								<ParamInput>
									<InputNumber {...inputNumberProps} value={settings.count.points} step={100}
												 onChange={value => onChangeCount('points', value)}
												 min={10000} max={270000} onBlur={onBlurCount}/>
								</ParamInput>
							</ParamHeader>
							<ParamSlider>
								<Slider min={10000} max={270000} step={1000} value={settings.count.points}
										onChange={value => onChangeCount('points', value)}/>
							</ParamSlider>
							<ParamDescription>
								Укажите количество адресов, по которым осуществляется доставка.
							</ParamDescription>
						</Param>
						<Param>
							<ParamHeader>
								<ParamTitle>Количество автомобилей</ParamTitle>
								<ParamInput>
									<InputNumber {...inputNumberProps} value={settings.count.transport} step={100}
												 onChange={value => onChangeCount('transport', value)}
												 min={0} max={600} onBlur={onBlurCount}/>
								</ParamInput>
							</ParamHeader>
							<ParamSlider>
								<Slider min={0} max={600} step={5} value={settings.count.transport}
										onChange={value => onChangeCount('transport', value)}/>
							</ParamSlider>
							<ParamDescription>
								Укажите количество ТС, которые вы планируете оснастить датчиками или отслеживать штрафы
								ГИБДД.
							</ParamDescription>
						</Param>
						<Divider style={{marginBlock: 0}}/>
						<AdditionalOptionsWrapper>
							<AdditionalOptionsTitle>
								Дополнительные опции
							</AdditionalOptionsTitle>
							{options.map(option => (
								<Checkbox key={option.type} onChange={() => onChangeOptions(option.type)}
										  checked={settings.options[option.type]}>{option.title}</Checkbox>
							))}
						</AdditionalOptionsWrapper>
					</ParamsWrapper>

					<Badge.Ribbon text="2 месяца бесплатно" color="red">
						<PriceWrapper>
							<TotalPriceWrapper>
								Итого в месяц
								<TotalPrice>
									{prettyDigit(total)} ₽
								</TotalPrice>
							</TotalPriceWrapper>
							<PriceItems>
								{Object.keys(result).map(key => (
									<PriceItem key={'c' + key}>
										<PriceTitle>{result[key].title}</PriceTitle>
										{result[key].price ? prettyDigit(result[key].price) + ' ₽' : 'бесплатно'}
									</PriceItem>
								))}
							</PriceItems>
							<ButtonWrapper>
								<Button color="green" variant="solid" size="large">
									Отправить заявку
								</Button>
							</ButtonWrapper>
						</PriceWrapper>
					</Badge.Ribbon>

				</CalculatorWrapper>
			</ContentBlock>
		</>
	)
}

export default PriceScreen

const CalculatorWrapper = styled.div`
    display: flex;
    gap: 60px;
`
const ParamsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 450px;
    gap: 25px;
    padding: 24px 0;
    /*background-color: #F7F7F7;*/
`
const Param = styled.div`
    display: flex;
    flex-direction: column;
`
const ParamHeader = styled.div`
    display: flex;
    align-items: center;
`
const ParamTitle = styled.div`
    display: flex;
    flex: 1;
    font-size: 20px;
    font-weight: 450;
`
const ParamInput = styled.div`
    display: flex;
`
const ParamDescription = styled.div`
    display: flex;
    font-size: 14px;
    opacity: .8;
`
const ParamSlider = styled.div`
    display: flex;
    padding: 15px 0;
`
const AdditionalOptionsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-self: flex-start;
    gap: 7px;
`
const AdditionalOptionsTitle = styled.div`
    font-weight: 500;
    margin-bottom: 5px;
`
const PriceWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 500px;
    min-height: 100%;
    padding: 24px;
    gap: 40px;
    background-color: #EBF7EB;
    border-radius: 14px;
    overflow: hidden;
`
const TotalPriceWrapper = styled.div`
    display: flex;
    flex-direction: column;
`
const TotalPrice = styled.div`
    font-size: 32px;
    font-weight: bold;
`
const PriceItems = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    gap: 20px;;
`
const PriceItem = styled.div`
    display: flex;
`
const PriceTitle = styled.div`
    display: flex;
    flex: 1;
    font-weight: 550;
`
const ButtonWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-self: flex-start;
    font-weight: 550;
`
