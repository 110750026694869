import styled from "styled-components"
import ContentHeader from "./ContentHeader"

const ContentCard = ({title, media = null, children, ...props}) => {

	return (
		<Wrapper {...props}>
			<Info>
				<ContentHeader {...props}>{title}</ContentHeader>
				<Description {...props}>{children}</Description>
			</Info>
			{media ? <Media width={media.width}>
				{media ? <img width={media.width} loading="lazy" src={media.url}/> : null}
			</Media> : null}
		</Wrapper>
	)
}

export default ContentCard

const Wrapper = styled.div`
    display: flex;
    flex: 1;
    align-items: center;
    flex-direction: ${props => props.reverse ? 'row-reverse' : 'row'};
`
const Info = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    width: 450px;
`
const Media = styled.div`
    display: flex;
    justify-content: flex-end;
    width: ${props => props.width}px;
`
const Description = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 660px;
    font-size: ${props => props.mini ? 18 : 24}px;
    font-weight: 300;
`
