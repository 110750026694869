import ContentBlock from "../../components/UI/ContentBlock"
import ContentCard from "../../components/UI/ContentCard"
import styled from "styled-components"
import ContentHeader from "../../components/UI/ContentHeader"
import {useEffect, useState} from "react"
import ModalDownloadApp from "./components/ModalDownloadApp"
import InterestingSolutionsSwiper from "./components/InterestingSolutionsSwiper"

const MobileAppScreen = () => {

	const [selectedStore, setSelectedStore] = useState(null)

	useEffect(() => {
		document.title = 'АТС24 — Мобильное приложение'
	}, [])

	return (
		<>
			<ContentBlock>
				<ContentCard title="Мобильное приложение"
							 media={{
								 url: "/assets/img/png/mobile-app/iphone.png",
								 width: 350
							 }}
				>
					<p>
						Самый настоящий помощник водителя. Путевой лист, оптимизация маршрутов, электронные накладные,
						архив поездок, график работы и многое другое.
					</p>
					<StoreButtonsWrapper>
						<StoreButton onClick={() => setSelectedStore('google-play')} store="google-play"/>
						<StoreButton onClick={() => setSelectedStore('app-store')} store="app-store"/>
						<StoreButton onClick={() => setSelectedStore('app-gallery')} store="app-gallery"/>
					</StoreButtonsWrapper>
				</ContentCard>
			</ContentBlock>

			<ContentBlock background="#E9FAFF">
				<ContentCard title="Маршрутный лист" small>
					<p>
						Любая автоматизация — прекрасно. Но мы считаем, что ручной режим не менее важен, поэтому у нас
						есть оба инструмента.
					</p>
				</ContentCard>
			</ContentBlock>
			<ContentBlock top={0} bottom={0} background="#E9FAFF">
				<ContentCard
					reverse title="Автоматически" mini
					media={{
						url: "https://cdn.sanity.io/images/599r6htc/regionalized/4baf0143722b830307500d7d1b1c32c79a8f87d5-1560x1094.png?w=780&q=75&fit=max&auto=format&dpr=2",
						width: 780
					}}
				>
					<p>
						Мы используем только нужные и понятные настройки автоматического планирования, а более тонкие
						подгонки алгоритм выполнит самостоятельно.
					</p>
					<p>
						Вам необходимо только выбрать склад, указать приоритет, ограничения на количество адресов или
						времени работы, дальше автомат сделает всё за вас.
					</p>
				</ContentCard>

				<ContentCard
					title="Ручной режим" mini
					media={{
						url: "https://cdn.sanity.io/images/599r6htc/regionalized/b80d3a64d6988323c011088c92f65d7161e733cd-1108x1108.png?w=780&q=75&fit=max&auto=format&dpr=2",
						width: 780
					}}
				>
					<p>
						Более простого, удобного и информативного конструктора маршрутов вы ещё не встречали.
					</p>
					<p>
						Всё, как в операционных системах — копируйте, вырезайте и вставляйте точки прямо на карте.
					</p>
				</ContentCard>
			</ContentBlock>

			<ContentBlock background="#FFF2DF">
				<ContentCard title="Интересные решения">
					<p>
						Несколько интересных примеров, как мобильное приложение может решать нестандартные задачи.
					</p>
				</ContentCard>
				<InterestingSolutionsSwiper/>
			</ContentBlock>

			<ContentBlock>
				<TilesWrapper>
					<TileWrapper>
						<img width={540}
							 src="https://cdn.sanity.io/images/599r6htc/regionalized/6538929bdeab8c11b7e97274a48e55fb68c701c3-1560x1248.png?w=540&h=432&q=75&fit=max&auto=format&dpr=2"/>
						<ContentHeader mini>Работа в браузере</ContentHeader>
						<p>
							Никаких установок, серверов, выездов специалистов и других трудностей, которые встречаются в
							классичеком ПО.
						</p>
					</TileWrapper>
					<TileWrapper>
						<img width={540}
							 src="https://cdn.sanity.io/images/599r6htc/regionalized/6538929bdeab8c11b7e97274a48e55fb68c701c3-1560x1248.png?w=540&h=432&q=75&fit=max&auto=format&dpr=2"/>
						<ContentHeader mini>Очень быстрый</ContentHeader>
						<p>
							Вы когда-нибудь работали с 10 000 точками на карте? У нас вы можете ещё и управлять ими без
							торможений.
						</p>
					</TileWrapper>
					<TileWrapper>
						<img width={540}
							 src="https://cdn.sanity.io/images/599r6htc/regionalized/6538929bdeab8c11b7e97274a48e55fb68c701c3-1560x1248.png?w=540&h=432&q=75&fit=max&auto=format&dpr=2"/>
						<ContentHeader mini>Маршруты по дорогам</ContentHeader>
						<p>
							Мы считаем все маршруты прямо по дорогам, и отдаём точные данные о расстояниях, времени и
							схемы движения.
						</p>
					</TileWrapper>
					<TileWrapper>
						<img width={540}
							 src="https://cdn.sanity.io/images/599r6htc/regionalized/6538929bdeab8c11b7e97274a48e55fb68c701c3-1560x1248.png?w=540&h=432&q=75&fit=max&auto=format&dpr=2"/>
						<ContentHeader mini>Метрики</ContentHeader>
						<p>
							По каждому маршруту мы отдаем плановое время выезда, прибытия на точки и возвращение на
							базу, если это необходимо.
						</p>
					</TileWrapper>
					<TileWrapper>
						<img width={540}
							 src="https://cdn.sanity.io/images/599r6htc/regionalized/6538929bdeab8c11b7e97274a48e55fb68c701c3-1560x1248.png?w=540&h=432&q=75&fit=max&auto=format&dpr=2"/>
						<ContentHeader mini>У водителя тоже</ContentHeader>
						<p>
							В приложении водитель в любой момент может оптимизировать маршрут для оставшихся точек в
							случае нештатной ситуации.
						</p>
					</TileWrapper>
					<TileWrapper>
						<img width={540}
							 src="https://cdn.sanity.io/images/599r6htc/regionalized/6538929bdeab8c11b7e97274a48e55fb68c701c3-1560x1248.png?w=540&h=432&q=75&fit=max&auto=format&dpr=2"/>
						<ContentHeader mini>Геокодирование</ContentHeader>
						<p>
							У вас не хранятся координаты клиентов? Не проблема, мы геокодируем адреса и даём возможность
							указать место разгрузки.
						</p>
					</TileWrapper>
				</TilesWrapper>
			</ContentBlock>

			<ContentBlock background="#F3FFE3">
				<ContentCard
					reverse title="Быстрый поиск" mini
					media={{
						url: "https://cdn.sanity.io/images/599r6htc/regionalized/4baf0143722b830307500d7d1b1c32c79a8f87d5-1560x1094.png?w=780&q=75&fit=max&auto=format&dpr=2",
						width: 780
					}}
				>
					<p>
						Наш поиск мгновенно находит информацию по более чем 30 полям внутри водителей,
						автомобилей, маршрутов, адресов и данных клиентов..
					</p>
				</ContentCard>

				<ContentCard
					title="Метки" mini
					media={{
						url: "https://cdn.sanity.io/images/599r6htc/regionalized/b80d3a64d6988323c011088c92f65d7161e733cd-1108x1108.png?w=780&q=75&fit=max&auto=format&dpr=2",
						width: 780
					}}
				>
					<p>
						Более простого, удобного и информативного конструктора маршрутов вы ещё не встречали.
					</p>
					<p>
						Всё, как в операционных системах — копируйте, вырезайте и вставляйте точки прямо на карте.
					</p>
				</ContentCard>

				<ContentCard
					reverse
					title="Персональные фильтры" mini
					media={{
						url: "https://cdn.sanity.io/images/599r6htc/regionalized/4baf0143722b830307500d7d1b1c32c79a8f87d5-1560x1094.png?w=780&q=75&fit=max&auto=format&dpr=2",
						width: 780
					}}
				>
					<p>
						Более простого, удобного и информативного конструктора маршрутов вы ещё не встречали.
					</p>
					<p>
						Всё, как в операционных системах — копируйте, вырезайте и вставляйте точки прямо на карте.
					</p>
				</ContentCard>
			</ContentBlock>

			<ContentBlock>
				<ContentCard title="Перенос точек" small
							 media={{
								 url: "https://cdn.sanity.io/images/599r6htc/regionalized/4baf0143722b830307500d7d1b1c32c79a8f87d5-1560x1094.png?w=780&q=75&fit=max&auto=format&dpr=2",
								 width: 780
							 }}
				>
					<p>
						В пути сломалось ТС или не вышел водитель? Не проблема! Вы можете быстро сменить транспорт,
						водителя или перераспределить оставшиеся точки по другим маршрутам.
					</p>
				</ContentCard>
			</ContentBlock>

			<ContentBlock>
				<ContentCard reverse title="Печать документов" small
							 media={{
								 url: "https://cdn.sanity.io/images/599r6htc/regionalized/4baf0143722b830307500d7d1b1c32c79a8f87d5-1560x1094.png?w=780&q=75&fit=max&auto=format&dpr=2",
								 width: 780
							 }}
				>
					<p>
						Нажмите правой кнопкой мыши на маршрут, выберите нужный документ (путевой лист или задание) и
						отправьте его на печать. В
						нем уже будет автоматически заполнена форма с данными водителя, ТС и списком адресов.
					</p>
				</ContentCard>
			</ContentBlock>

			<ModalDownloadApp selectedStore={selectedStore} setSelectedStore={setSelectedStore}/>
		</>
	)
}

export default MobileAppScreen

const StoreButtonsWrapper = styled.div`
    display: flex;
    gap: 20px;
    padding: 40px 0;
`
const StoreButton = styled.div`
    width: 169px;
    height: 50px;
    background-image: url(${props => "/assets/img/png/mobile-app/" + props.store + ".png"});
    background-size: 100% auto;
    transition: opacity .3s;
    cursor: pointer;

    :hover {
        opacity: .7;
    }
`
const TilesWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 80px;
    width: 1160px;
`
const TileWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 540px;

    img {
        margin-bottom: 32px;
    }
`
